import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'
import lightbox from 'lightbox2'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      autoplay: true,
      dots: true,
      infinite: true,
    },
    homeSlider: {
      arrows: false,
      autoplay: true,
      infinite: true,
      dots: true,
      appendDots: $('.section__slider-dots'),
    },
    variableWidth: {
      arrows: false,
      autoplay: false,
      infinite: false,
      variableWidth: true,
      centerMode: false,
    },
    cardsSlider: {
      arrows: false,
      dots: true,
      infinite: false,
      slidesToShow: 2,
      responsive: [{
        breakpoint: 832,
        settings: {
          slidesToShow: 1,
        }
      }]
    }
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}

/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a:not([href="#"])')
  const header = $('.header--main')

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $('.menu--primary .menu-item-has-children > a').on('click', function(e) {
    e.preventDefault()
    $(window).width() < 832 ? $(this).next().slideToggle() : $(this).parent().toggleClass('open')
  })

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* SETUP LIGHTBOX2
  ----------------------------- */
export const setupLightbox = () => {
  lightbox.option({
    'resizeDuration': 200,
    'positionFromTop': 100,
    'showImageNumberLabel': false
  })
}

/* LIST SECTION IMAGES ON MOUSEOVER
  ----------------------------- */
export const sectionListMouseover = () => {
  $('.section--home-process,.page--content .page__content--e_features_list').each(function() {
    $(this).find('.step-item').each(function() {
      $(this).on('mouseenter', function() { $('#' + $(this).data('step')).addClass('active')})
      $(this).on('mouseleave', function() { $('#' + $(this).data('step')).removeClass('active')})
    })
  })
}

/* CARD BODY TOGGLE
  ----------------------------- */
export const pageCardBodyToggle = () => {
  $('.page__content--g_cards_repeater .page__card').each(function() {
    $(this).find('.page__card-show-more').on('click', function() {
      $(this).parent().find(".short-text, .full-text").toggle()
      $(this).hide()
    })
  })
}