import $ from 'jquery'
import * as project from './functions'
import './polyfill'

window.onload = () => {
  // document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + (1 / window.devicePixelRatio) + ', maximum-scale=1.0, user-scalable=0')

  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  project.setupLightbox()
  project.sectionListMouseover()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        break
      case "page-template-default":
        project.pageCardBodyToggle()
        break
      default:
        $('#loader').fadeOut(500)
    }
  })
}
